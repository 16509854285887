import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Polyline,
  useJsApiLoader,
  DirectionsRenderer,
  DirectionsService,
} from '@react-google-maps/api';
import { useDebouncedState, useIdle, useShallowEffect } from '@mantine/hooks';
import {
  Box,
  Center,
  Group,
  Image,
  Loader,
  Stack,
  Switch,
  Text,
  useMantineTheme,
} from '@mantine/core';

import { TbLocationFilled } from 'react-icons/tb';

import { v4 as uuidv4 } from 'uuid';

import { ActionIcon, createStyles } from '@mantine/core';

import { useParams, useSearchParams } from 'react-router-dom';

import { Circle, Marker, useGoogleMap } from '@react-google-maps/api';

import roundMarker from '../assets/round-marker.svg';

import { ReactComponent as NavigateCircle } from '../assets/navigate-circle.svg';
import { ReactComponent as IconRouteAlpha } from '../assets/iconRouteAlpha.svg';

import redCircleMarker from '../assets/red-circle-marker.svg';
import redCircleCheckMarker from '../assets/red-circle-check.svg';
import endFlag from '../assets/end-flag.svg';
import redMapMarker from '../assets/map-marker.svg';
import blueRoundMarker from '../assets/round-marker.svg';
import purpleCircleMarker from '../assets/purple-circle-marker.svg';
import routeAlpha from '../assets/route_002.png';
import redArrowNavigation from '../assets/red-arrow-navigation.svg';

// // Firebase
import settings from '../config/settings';

import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  query,
  ref,
  limitToLast,
  get,
  onChildAdded,
} from 'firebase/database';

import { useDatabaseUpdateMutation } from '@react-query-firebase/database';
import { database } from '../firebase';

import {
  IconArrowBigDownLines,
  IconArrowBigRightLine,
  IconArrowBigUpLines,
  IconArrowRampRight3,
  IconArrowUpRightCircle,
  IconCar,
  IconCompass,
  IconCrosshair,
  IconCurrentLocation,
  IconDirectionSign,
  IconGps,
  IconLocation,
  IconMapPin,
  IconMoonStars,
  IconNavigation,
  IconRadar2,
  IconRoute,
  IconRouteOff,
  IconSun,
  IconTarget,
  IconWalk,
} from '@tabler/icons';

import useUserData from '../store/useUserStore';
import {
  gpsDistance,
  processSpeedMph,
  showField,
  getTimeFromTimestamp,
} from '../utils/formatProfile';

import { useLayoutEffect } from 'react';
import { useRef } from 'react';
import {
  MdDirectionsCarFilled,
  MdOutlineAltRoute,
  MdOutlineDirectionsCar,
  MdOutlineDirectionsCarFilled,
} from 'react-icons/md';
import { toast } from 'react-hot-toast';

import { useWakeLock } from 'react-screen-wake-lock';
import { useCallback } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

// const googleMapsApiKey = settings.googleMapsApiKey;
const googleMapsApiKey = settings.googleMapsDirectionsApiKey;
// const googleMapsDirectionsApiKey = settings.googleMapsDirectionsApiKey;

// const firebaseApp = initializeApp(settings.firebaseConfig);
// const database = getDatabase(firebaseApp);

const mapOptions = {
  gestureHandling: 'greedy',
  // disableDefaultUI: true,
};

const lastKnownLocationPath = [];
var path = [];

const drivingOptions = {
  trafficModel: 'optimistic',
};

const isPosition = position => {
  if (typeof position === 'undefined' || position === null) return false;

  if (!!Object.keys(position).length) {
    if (position?.lat && position?.lng) return true;
  }

  return false;
};

let contactLocation = {
  lat: 0,
  lng: 0,
  hAccuracy: 50,
};

function MapContainerMobile() {
  const { classes } = useStyles();
  const { sessionId } = useParams();

  const URLparams = new URLSearchParams(location.search);

  const [direction, setDirection] = useState(false);
  const [directions, setDirections] = useState(null);

  const [overQueryLimitDelay, setOverQueryLimitDelay] = useState(false);
  const [isDirectionLoading, setIsDirectionLoading] = useState(false);

  const {
    isSupported: isSupportedWakeLockApi,
    released,
    request,
    release,
  } = useWakeLock({
    onRequest: () => {},
    onError: () => {},
    onRelease: () => {},
  });

  const directionCount = useRef(0);

  const directionsCallback = (result, status) => {
    if (typeof status === 'undefined' || typeof result === 'undefined') return;
    if (status === 'OK' && directionCount.current === 0) {
      directionCount.current++;
      setDirections(result);
      console.log('directionsCallback', result);
      setOverQueryLimitDelay(false);
      setIsDirectionLoading(false);
    } else if (
      status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT
    ) {
      console.log('Try Again - OVER_QUERY_LIMIT');
      setOverQueryLimitDelay(true);
      setTimeout(function () {
        // directionCount.current = 0;
        setOverQueryLimitDelay(false);
        setIsDirectionLoading(false);
      }, 5000);
    } else {
      setOverQueryLimitDelay(false);
      setIsDirectionLoading(false);
    }
  };

  const theme = useMantineTheme();

  const userLocation = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isGeoLocationLoading, setIsGeoLocationLoading] = useState(false);

  const [ticking, setTicking] = useState(false),
    [count, setCount] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const reloadKey = 'directionKey';
  const directionKey = URLparams.get(reloadKey);

  const mapStyle = searchParams.get('style')
    ? searchParams.get('style')
    : 'alpha';

  // const drivingMode = 'WALKING';
  // const drivingMode = URLparams.get('mode');
  const drivingMode = URLparams.get('mode')
    ? URLparams.get('mode') === 'WALKING'
      ? 'WALKING'
      : URLparams.get('mode') === 'DRIVING'
      ? 'DRIVING'
      : 'DRIVING'
    : 'DRIVING';

  // const [drivingMode, setDrivingMode] = useState(
  //   URLparams.get('mode') ? URLparams.get('mode') : 'DRIVING'
  // );

  const [drivingModeStatus, setDrivingModeStatus] = useState(drivingMode);

  const { setLastKnownLocation } = useUserData();

  const { setCurrentLocationData } = useUserData();

  const idle = useIdle(5000, { initialState: true });
  const [firstIdle, setFirstIdle] = useState(true);

  const [centerLocation, setCenterLocation] = useState(false);
  const [centerViewerLocation, setCenterViewerLocation] = useState(false);
  const [centerMap, setCenterMap] = useState({
    lat: 0,
    lng: 0,
  });

  const [viewerLocation, setViewerLocation] = useState({
    lat: 0,
    lng: 0,
    hAccuracy: 50,
    heading: null,
    userDirection: null,
  });

  const [viewerPosition, setViewerPosition] = useState({
    course: null,
    heading: null,
    userDirection: null,
    floor: 0,
    hAccuracy: 0,
    latitude: 0,
    longitude: 0,
    speed: null,
    timestamp: 0,
    vAccuracy: 0,
  });

  console.log('viewerLocation', viewerLocation);

  const [lastKnownLocationInfoWindow, setLastKnownLocationInfoWindow] =
    useState(false);
  const [initialInfoWindow, setInitialInfoWindow] = useState(false);
  const [currentInfoWindow, setCurrentInfoWindow] = useState(false);
  const [viewerInfoWindow, setViewerInfoWindow] = useState(false);
  const [endAddressInfoWindow, setEndAddressInfoWindow] = useState(false);

  const [polylines, setPolylines] = useState([]);

  // Polyline default fill style
  const options = {
    strokeColor: '#DF5653',
    strokeOpacity: 1,
    strokeWeight: 5,
    fillColor: '#DF5653',
    fillOpacity: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    geodesic: true,
    zIndex: 100,
  };

  const viewerRef = ref(
    database,
    'sessions/' + sessionId + '/viewers_gps_data'
  );

  const viewerMutation = useDatabaseUpdateMutation(viewerRef);

  function getCurrentDirection(previousCoordinates, currentCoordinates) {
    const diffLat =
      currentCoordinates && previousCoordinates
        ? currentCoordinates?.lat - previousCoordinates?.lat
        : 0;
    const diffLng =
      currentCoordinates && previousCoordinates
        ? currentCoordinates?.lng - previousCoordinates?.lng
        : 0;
    const anticlockwiseAngleFromEast = convertToDegrees(
      Math.atan2(diffLat, diffLng)
    );
    const clockwiseAngleFromNorth = 90 - anticlockwiseAngleFromEast;
    return clockwiseAngleFromNorth;

    function convertToDegrees(radian) {
      return (radian * 180) / Math.PI;
    }
  }

  const onSetViewerLocation = useCallback(
    async position => {
      console.log('viewerLocation_position', position);

      const previousCoordinates = userLocation.current;

      userLocation.current = {
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      };

      const userDirection = getCurrentDirection(
        previousCoordinates,
        userLocation?.current
      );

      contactLocation = {
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
        hAccuracy: position?.coords.accuracy,
        heading: position?.coords?.heading
          ? parseFloat(position?.coords?.heading)
          : null,
        userDirection,
      };

      setViewerLocation({
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude),
        hAccuracy: parseFloat(position.coords.accuracy),
        heading: position?.coords?.heading
          ? parseFloat(position?.coords?.heading)
          : null,
      });

      setViewerPosition({
        course: null,
        floor: null,
        heading: position?.coords?.heading
          ? String(parseFloat(position?.coords?.heading))
          : userDirection
          ? String(userDirection)
          : null,
        hAccuracy: String(position?.coords?.accuracy),
        latitude: String(position?.coords?.latitude),
        longitude: String(position?.coords?.longitude),
        speed: position?.coords?.speed
          ? String(position?.coords?.speed ?? 0)
          : null,
        timestamp: String(Math.floor(new Date().getTime() / 1000)),
        vAccuracy: String(position?.coords?.altitudeAccuracy),
      });
    },
    [viewerLocation, setViewerLocation, setViewerPosition, contactLocation]
  );

  // function onSetViewerLocation(position) {
  //   console.log('viewerLocation_position', position);
  //   contactLocation = {
  //     lat: position.coords.latitude,
  //     lng: position.coords.longitude,
  //     hAccuracy: position.coords.accuracy,
  //   };
  // }

  const handleDirection = useCallback(async () => {
    if (direction) {
      setDirection(o => !o);
      URLparams.delete('mode');
      URLparams.delete('directionKey');
      setDirections(null);
      setSearchParams(URLparams);
      return;
    }

    directionCount.current = 0;

    const permissionStatus = await navigator?.permissions?.query({
      name: 'geolocation',
    });
    const hasPermission = permissionStatus?.state;

    if (hasPermission === 'prompt') {
      // toast('Allow SayHelp to access your location', {
      //   icon: <IconRadar2 stroke={1.5} />,
      //   id: 'permission',
      // });
    }

    if (navigator.geolocation) {
      setIsGeoLocationLoading(true);

      navigator.geolocation.getCurrentPosition(
        position => {
          contactLocation = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
            hAccuracy: parseFloat(position.coords.accuracy),
            heading: position?.coords?.heading
              ? parseFloat(position?.coords?.heading)
              : null,
          };

          setViewerLocation({
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
            hAccuracy: parseFloat(position.coords.accuracy),
            heading: position?.coords?.heading
              ? parseFloat(position?.coords?.heading)
              : null,
          });

          setViewerPosition({
            course: null,
            floor: null,
            heading: position?.coords?.heading
              ? String(parseFloat(position?.coords?.heading))
              : null,
            hAccuracy: String(position?.coords?.accuracy),
            latitude: String(position?.coords?.latitude),
            longitude: String(position?.coords?.longitude),
            speed: position?.coords?.speed
              ? String(position?.coords?.speed ?? 0)
              : null,
            timestamp: String(Math.floor(new Date().getTime() / 1000)),
            vAccuracy: String(position?.coords?.altitudeAccuracy),
          });

          navigator.geolocation.watchPosition(onSetViewerLocation, () => {}, {
            enableHighAccuracy: true,
            timeout: 5000,
          });

          setDirection(o => !o);
          directionCount.current = 0;

          setIsGeoLocationLoading(false);
          setCenterViewerLocation(true);
        },
        () => {
          console.log('Geolocation Request Denied');
          setIsGeoLocationLoading(false);

          // toast('Allow SayHelp to access your Geolocation', {
          //   icon: <IconRadar2 stroke={1.5} />,
          // });

          // toast("Can't access Your Location", {
          //   icon: <IconRadar2 stroke={1.5} />,
          // });
        }
      );
    } else {
      // Browser doesn't support Geolocation
      console.log("Browser doesn't support Geolocation");
      setIsGeoLocationLoading(false);
    }
  }, [directions, drivingMode]);

  const handleViewerLocation = useCallback(async () => {
    const permissionStatus = await navigator?.permissions?.query({
      name: 'geolocation',
    });
    const hasPermission = permissionStatus?.state;

    if (hasPermission === 'prompt') {
      // toast('Allow SayHelp to access your location', {
      //   icon: <IconRadar2 stroke={1.5} />,
      //   id: 'permission',
      // });
    }

    if (navigator.geolocation) {
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(onSetViewerLocation, () => {}, {
          enableHighAccuracy: true,
          timeout: 5000,
        });

        setCenterViewerLocation(true);
        console.log('Geolocation Granted');
      } else {
        // Browser doesn't support Geolocation
        console.log("Browser doesn't support Geolocation");
      }
    }
  }, [
    viewerLocation,
    setViewerLocation,
    setViewerPosition,
    centerViewerLocation,
    setCenterViewerLocation,
  ]);

  const participantSessionId = JSON.parse(
    localStorage.getItem(String(sessionId ?? ''))
  );

  useShallowEffect(() => {
    console.log('viewerLocation_viewerLocation', viewerLocation);
    console.log('viewerLocation_viewerLocation_viewerId', participantSessionId);

    if (
      participantSessionId &&
      viewerPosition?.latitude &&
      viewerPosition?.longitude
    ) {
      const viwerRecord = {
        [participantSessionId]: viewerPosition,
      };
      console.log(
        'viewerLocation_viewerLocation_record to database',
        viwerRecord
      );
      viewerMutation.mutate(viwerRecord);
    }
  }, [viewerPosition, viewerLocation, participantSessionId]);

  const handleAutoDirection = useCallback(async () => {
    // if (direction) {
    //   // setDirection(o => !o);

    //   return;
    // }
    directionCount.current = 0;
    setDirection(true);

    const permissionStatus = await navigator?.permissions?.query({
      name: 'geolocation',
    });
    const hasPermission = permissionStatus?.state;

    if (hasPermission === 'prompt') {
      // toast('Allow SayHelp to access your location', {
      //   icon: <IconRadar2 stroke={1.5} />,
      //   id: 'permission',
      // });
    }

    if (navigator.geolocation) {
      setIsGeoLocationLoading(true);

      navigator.geolocation.getCurrentPosition(
        position => {
          contactLocation = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
            hAccuracy: parseFloat(position.coords.accuracy),
            heading: position?.coords?.heading
              ? parseFloat(position?.coords?.heading)
              : null,
          };
          setViewerLocation({
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
            hAccuracy: parseFloat(position.coords.accuracy),
            heading: position?.coords?.heading
              ? parseFloat(position?.coords?.heading)
              : null,
          });

          setViewerPosition({
            course: null,
            floor: null,
            heading: position?.coords?.heading
              ? String(parseFloat(position?.coords?.heading))
              : null,
            hAccuracy: String(position?.coords?.accuracy),
            latitude: String(position?.coords?.latitude),
            longitude: String(position?.coords?.longitude),
            speed: position?.coords?.speed
              ? String(position?.coords?.speed ?? 0)
              : null,
            timestamp: String(Math.floor(new Date().getTime() / 1000)),
            vAccuracy: String(position?.coords?.altitudeAccuracy),
          });

          navigator.geolocation.watchPosition(onSetViewerLocation, () => {}, {
            enableHighAccuracy: true,
            timeout: 5000,
          });

          // setDirection(o => !o);
          directionCount.current = 0;

          setIsGeoLocationLoading(false);
          setCenterViewerLocation(true);
        },
        () => {
          console.log('Geolocation Request Denied');
          setIsGeoLocationLoading(false);

          // toast('Allow SayHelp to access your Location', {
          //   icon: <IconRadar2 stroke={1.5} />,
          //   id: 'permission',
          // });
        }
      );
    } else {
      // Browser doesn't support Geolocation
      console.log("Browser doesn't support Geolocation");
      setIsGeoLocationLoading(false);
    }
  }, [directions, drivingMode]);

  useShallowEffect(() => {
    if (directionKey && direction) {
      console.log('Recreate path for driving mode');

      directionCount.current = 0;
      handleAutoDirection();
    }
  }, [directionKey, direction]);

  function onReloadDirection() {
    const reloadKey = 'directionKey';
    const reloadId = uuidv4();

    const params = new URLSearchParams(location.search);

    params.set(reloadKey, reloadId);
    setSearchParams(params);
  }

  useShallowEffect(() => {
    if (URLparams.get('mode') && direction === false) {
      console.log('Set First');
      setTicking(true);
      setDirection(true);
      directionCount.current = 0;
      onReloadDirection();
    }
  }, [drivingMode, direction]);

  useShallowEffect(() => {
    if (URLparams.get('mode') && directions == null) {
      setTimeout(() => {
        setDirection(true);
        handleAutoDirection();
      }, 1000);
    }
  }, [drivingMode]);

  const getCurrentLocation = sessionId => {
    const lastLocationQuery = query(
      ref(database, 'sessions/' + sessionId + '/last_known_location'),
      limitToLast(1)
    );

    get(lastLocationQuery)
      .then(snapshot => {
        if (snapshot.exists()) {
          snapshot.forEach(childSnapshot => {
            const position = {
              lat: parseFloat(childSnapshot.val().latitude),
              lng: parseFloat(childSnapshot.val().longitude),
              hAccuracy: parseFloat(childSnapshot.val().hAccuracy),
              timestamp: childSnapshot.val().timestamp,
              speed: childSnapshot.val().speed,
              course: childSnapshot.val().course,
            };

            lastKnownLocationPath.push(position);

            setLastKnownLocation(childSnapshot.key, childSnapshot.val());

            setCenterMap(position);

            setIsLoading(false);
            setCenterLocation(true);
          });
        } else {
          console.log(
            'last_known_location: No Last Known Location Data Available'
          );
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });

    const lastLocationRef = ref(
      database,
      'sessions/' + sessionId + '/gps_data'
    );

    return onChildAdded(lastLocationRef, childSnapshot => {
      if (childSnapshot.exists()) {
        const fullPosition = childSnapshot.val();
        setCurrentLocationData(sessionId, childSnapshot.val());

        const position = {
          lat: parseFloat(childSnapshot.val().latitude),
          lng: parseFloat(childSnapshot.val().longitude),
          hAccuracy: parseFloat(childSnapshot.val().hAccuracy),
          timestamp: childSnapshot.val().timestamp,
          speed: childSnapshot.val().speed,
          course: childSnapshot.val().course,
        };

        // correct way to save path
        // const newPath = new window.google.maps.LatLng(position);
        const newPath = position;
        path.push(position);

        if (path.length === 1) setCenterMap(position);

        setIsLoading(false);
      } else {
        console.log('Waiting for gps_data...');
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    // isLoading - wait for data loading from database -> state -> map component
    setIsLoading(true);
    const getCurrentLocationOff = getCurrentLocation(sessionId);

    return () => {
      // Unsubscribe from Realtime Database Listener
      getCurrentLocationOff();
    };
  }, []);

  useEffect(() => {
    if (isSupportedWakeLockApi) {
      if (released === false) release();
      else request();
    }
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
  });

  // Circle default style
  const circleOptions = {
    strokeColor: '#EC1C24',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#EC1C24',
    fillOpacity: 0.2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    // radius: 50,
    radius: 50,
    zIndex: 9,
  };

  const purpleCircleOptions = {
    strokeColor: '#8166F6',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#8166F6',
    fillOpacity: 0.2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    // radius: 50,
    radius: 50,
    zIndex: 9,
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    flexGrow: 1,
  };

  const CenterViewerLocationMap = () => {
    const googleMap = useGoogleMap();

    useEffect(() => {
      if (googleMap) {
        if (centerViewerLocation) {
          if (viewerLocation?.lat && viewerLocation?.lng) {
            googleMap.panTo(viewerLocation);
            // googleMap.setZoom(15);
            setCenterViewerLocation(false);
          }
        }
      }
    }, []);
  };

  const CenterMap = () => {
    const googleMap = useGoogleMap();

    useEffect(() => {
      if (googleMap) {
        if (centerLocation) {
          if (path.length) {
            googleMap.panTo(path?.at(-1));
            googleMap.setZoom(15);
            setCenterLocation(false);
          } else {
            if (lastKnownLocationPath.length) {
              googleMap.panTo(lastKnownLocationPath?.at(0));
              googleMap.setZoom(15);
              setCenterLocation(false);
            }
          }
        }
      }
    }, []);
  };

  console.log('directions', directions);
  useEffect(() => {
    if (!idle) setFirstIdle(false);
  }, [idle]);

  // zoomOut after Marker go out off screen - fitBounds functions
  const ZoomOut = () => {
    const googleMap = useGoogleMap();
    // console.log('googleMap: ', googleMap);

    // Emergency contact requested direction path build don't zoom out map in auto mode
    if (direction) return;

    let fitBounceIndex = 50;
    let startBounds = 0;

    let currentLength = path?.length ?? 0;

    startBounds =
      currentLength > fitBounceIndex ? currentLength - fitBounceIndex : 0;

    let bounds = new window.google.maps.LatLngBounds();

    if (path?.at(startBounds)) bounds.extend(path?.at(startBounds));

    useLayoutEffect(() => {
      if (googleMap && idle && firstIdle && path.length > fitBounceIndex) {
        if (path?.at(-1)) {
          bounds.extend(path?.at(-1));

          // Pan without zoom
          googleMap.panToBounds(bounds);

          // Pan with zommout effect
          // googleMap.fitBounds(bounds);
          googleMap.panTo(path?.at(-1));
        }
      } else {
        if (idle && firstIdle) {
          if (path?.at(-1)) {
            googleMap.panTo(path?.at(-1));
            // googleMap.setZoom(15);
          }
        }
      }
    }, []);
  };
  useEffect(() => {
    console.log('count', count);
    console.log('count_directionKey', directionKey);
    const timer = setTimeout(() => {
      if (ticking) {
        setCount(count + 1);
        onReloadDirection();
      }
    }, 60000);
    return () => clearTimeout(timer);
  }, [count, ticking]);

  console.log('viewerLocation', viewerLocation);
  // console.log('contactLocation', contactLocation);

  const PolylineColor = ({ path }) => {
    const googleMap = useGoogleMap();

    let infowindow = [];

    let gpsDateString = '';

    const polylineStyleDefault = {
      strokeColor: '#D85040',
      strokeOpacity: 1,
      strokeWeight: 5,
    };

    // Colors - Dynamically Building Route

    const polylineStyleBlue = {
      strokeColor: '#4DAFF9',
    };

    const polylineStyleOrange = {
      strokeColor: '#E59C37',
    };
    const polylineStyleTeal = {
      strokeColor: '#4879C1',
    };

    const polylineStyleRed = {
      strokeColor: '#E25241',
    };

    const polylineStyleLightGrey = {
      strokeColor: '#BCBDBF',
      strokeOpacity: 0.7,
      strokeWeight: 7,
    };

    const polylineStyleGreen = {
      strokeColor: '#94C761',
    };

    useEffect(() => {
      if (googleMap) {
        if (path.length >= 2) {
          for (let index = 0; index < path.length - 1; index += 1) {
            // Latest Stable
            const pos1 = path[path.length - 1 - index];
            const pos2 = path[path.length - 1 - index - 1];

            const distance =
              gpsDistance(pos1.lat, pos1.lng, pos2.lat, pos2.lng, 'K') * 1000;

            //  isGpsAccuracy by distance - 6km - light grey
            const isGpsAccuracy = distance > 6000;

            const speedMph = processSpeedMph(pos2.speed);

            // Extra Low Speed - red
            const isExtraLowSpeed = speedMph < 40;

            // Low Speed - orange
            const isLowSpeed = speedMph > 40 && speedMph < 60;

            // Regular Speed - blue
            const isRegularSpeed = speedMph > 60 && speedMph < 80;

            // High Speed - Green
            const isHighSpeed = speedMph > 80;

            let currentPolylineStyle = {
              ...polylineStyleDefault,
            };

            if (isExtraLowSpeed)
              currentPolylineStyle = {
                ...currentPolylineStyle,
                ...polylineStyleRed,
              };

            if (isLowSpeed)
              currentPolylineStyle = {
                ...currentPolylineStyle,
                ...polylineStyleBlue,
              };

            if (isRegularSpeed)
              currentPolylineStyle = {
                ...currentPolylineStyle,
                ...polylineStyleTeal,
              };

            if (isHighSpeed)
              currentPolylineStyle = {
                ...currentPolylineStyle,
                ...polylineStyleGreen,
              };

            if (isGpsAccuracy)
              currentPolylineStyle = {
                ...currentPolylineStyle,
                ...polylineStyleLightGrey,
              };

            if (mapStyle === 'alpha') {
              const currentPolyline = polylines;
              if (currentPolyline[index]) currentPolyline[index].setMap(null);

              let currentOpacity = 1;

              currentOpacity = 1 - 0.02 * (index + 0.001);

              currentOpacity = currentOpacity > 0 ? currentOpacity : 0;

              if (Boolean(currentOpacity)) {
                currentPolyline[index] = new window.google.maps.Polyline({
                  path: [pos1, pos2],
                  strokeColor: '#D85040',
                  strokeOpacity: currentOpacity,
                  strokeWeight: 5,
                });

                currentPolyline[index].setMap(googleMap);

                setPolylines(currentPolyline);
              }
            }

            if (mapStyle === 'solid') {
              const currentPolyline = polylines;

              if (currentPolyline[index]) currentPolyline[index].setMap(null);

              currentPolyline[index] = new window.google.maps.Polyline({
                path: [pos1, pos2],
                ...polylineStyleDefault,
              });
              currentPolyline[index].setMap(googleMap);

              setPolylines(currentPolyline);
            }

            if (mapStyle === 'color') {
              const currentPolyline = polylines;
              if (currentPolyline[index]) currentPolyline[index].setMap(null);

              currentPolyline[index] = new window.google.maps.Polyline({
                path: [pos1, pos2],
                map: googleMap,
                ...currentPolylineStyle,
              });

              setPolylines(currentPolyline);
            }
          }
        }
      }
    }, [path]);
  };

  if (lastKnownLocationPath.length === 0 && path.length === 0)
    return (
      <Stack align="center" pt={20}>
        <Loader color="blue" />
        <Text order={3} color="white">
          Waiting for location data...
        </Text>
      </Stack>
    );

  if (isLoading)
    return (
      <Stack align="center" pt={20}>
        <Loader color="red" />
      </Stack>
    );

  const PolylineRender = () => {
    if (mapStyle) {
      if (mapStyle === 'solid')
        return <Polyline path={path} options={options} />;
    }
    // return <PolylineColor path={path} />;

    return <></>;

    // return <Polyline path={path} options={options} />;
  };

  const handleSwitch = () => {
    const URLparams = new URLSearchParams(location.search);
    if (mapStyle === 'alpha') URLparams.set('style', 'solid');
    else if (mapStyle === 'solid') URLparams.set('style', 'alpha');
    setSearchParams(URLparams);
  };

  return isLoaded ? (
    <>
      <div className={classes.container}>
        <Stack className={classes.mapControl} position="center" spacing="sm">
          <ActionIcon
            variant="default"
            color="#DA3831"
            radius="lg"
            size={50}
            sx={theme => ({
              color: mapStyle === 'alpha' ? 'white' : theme.colors.blue[6],
              border:
                mapStyle === 'solid' ? '2px solid RGBA(218, 56, 49, 0.6)' : '',
              boxShadow: theme.shadows.sm,
            })}
            onClick={handleSwitch}>
            {mapStyle === 'solid' ? (
              <IconRouteOff color="#DA3831" size={30} />
            ) : (
              // <Image src={routeAlpha} width={22} />
              <IconRoute color="#DA3831" size={30} />
            )}
          </ActionIcon>
          <ActionIcon
            variant={direction ? 'fill' : 'default'}
            color="#DA3831"
            radius="lg"
            size={50}
            // loading={isGeoLocationLoading && overQueryLimitDelay}
            loading={
              isDirectionLoading || overQueryLimitDelay || isGeoLocationLoading
            }
            // loading={isDirectionLoading}
            loaderProps={{ size: 'sm' }}
            sx={theme => ({
              color: !direction ? 'white' : theme.colors.blue[6],
              border: !direction ? '2px solid RGBA(218, 56, 49, 0.6)' : '',
              boxShadow: theme.shadows.sm,
            })}
            onClick={() => {
              setSearchParams({ style: 'alpha', mode: 'DRIVING' });
              directionCount.current = 0;
              if (!direction) {
                setTicking(true);
              } else setTicking(false);
              handleDirection();
            }}>
            <MdOutlineAltRoute
              size={30}
              stroke={2}
              color={!direction ? 'RGBA(218, 56, 49, 0.6)' : 'white'}
            />
          </ActionIcon>
          {direction && (
            <>
              <ActionIcon
                variant={drivingMode === 'DRIVING' ? 'fill' : 'default'}
                color="#DA3831"
                radius="lg"
                loading={drivingMode === 'DRIVING' && overQueryLimitDelay}
                loaderProps={{ size: 'sm' }}
                size={50}
                sx={theme => ({
                  color:
                    drivingMode === 'DRIVING' ? theme.colors.blue[6] : 'white',
                  border:
                    drivingMode === 'DRIVING'
                      ? ''
                      : '2px solid RGBA(218, 56, 49, 0.6)',
                  boxShadow: theme.shadows.sm,
                })}
                onClick={() => {
                  setSearchParams({ style: 'alpha', mode: 'DRIVING' });
                  directionCount.current = 0;
                  handleAutoDirection();
                }}>
                <MdOutlineDirectionsCar
                  size={30}
                  stroke={1}
                  color={
                    drivingMode === 'DRIVING'
                      ? 'white'
                      : 'RGBA(218, 56, 49, 0.6)'
                  }
                />
              </ActionIcon>
              <ActionIcon
                variant={drivingMode === 'WALKING' ? 'fill' : 'default'}
                color="#DA3831"
                radius="lg"
                loading={drivingMode === 'WALKING' && overQueryLimitDelay}
                loaderProps={{ size: 'sm' }}
                size={50}
                sx={theme => ({
                  color:
                    drivingMode === 'WALKING' ? theme.colors.blue[6] : 'white',
                  border:
                    drivingMode === 'WALKING'
                      ? ''
                      : '2px solid RGBA(218, 56, 49, 0.6)',
                  boxShadow: theme.shadows.sm,
                })}
                onClick={() => {
                  setSearchParams({ style: 'alpha', mode: 'WALKING' });
                  directionCount.current = 0;
                  handleAutoDirection();
                }}>
                <IconWalk
                  size={30}
                  stroke={1.5}
                  color={
                    drivingMode === 'WALKING'
                      ? 'white'
                      : 'RGBA(218, 56, 49, 0.6)'
                  }
                />
              </ActionIcon>
            </>
          )}
          <ActionIcon
            variant="fill"
            color="#DA3831"
            radius="50%"
            size={50}
            sx={theme => ({
              color: 'white',
              border: '2px solid #3170F5',

              boxShadow: theme.shadows.md,
            })}
            onClick={handleViewerLocation}>
            <TbLocationFilled color="#fff" size={30} />
          </ActionIcon>
        </Stack>

        <div className={classes.currentLocationIco}>
          <Box>
            <ActionIcon
              size={50}
              radius="lg"
              variant="default"
              sx={theme => ({ boxShadow: theme.shadows.sm })}
              onClick={() => setCenterLocation(true)}>
              {/* <IconCurrentLocation
                width={30}
                height={30}
                color="#DA3831"
                stroke={2}
              /> */}
              <NavigateCircle width={25} height={25} />
            </ActionIcon>
          </Box>
        </div>
      </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        options={mapOptions}
        center={centerMap}
        // heading={viewerLocation?.heading ?? null}
        // center={centerBase}
        zoom={15}>
        <>
          {direction && (
            <>
              {isPosition(path?.at(-1)) ? (
                <>
                  {directionCount.current === 0 && (
                    <DirectionsService
                      options={{
                        origin: contactLocation,
                        // origin: viewerLocation,
                        destination: path?.at(-1),
                        travelMode: drivingMode,
                      }}
                      callback={directionsCallback}
                      onLoad={() => {
                        setIsDirectionLoading(true);
                      }}
                    />
                  )}
                  {directions && (
                    <DirectionsRenderer
                      options={{
                        preserveViewport: true,
                        suppressMarkers: true,
                      }}
                      directions={directions}
                    />
                  )}
                </>
              ) : (
                <>
                  {isPosition(lastKnownLocationPath?.at(0)) && (
                    <>
                      {directionCount.current === 0 && (
                        <DirectionsService
                          options={{
                            origin: contactLocation,
                            // origin: viewerLocation,
                            destination: lastKnownLocationPath?.at(0),
                            travelMode: drivingMode,
                          }}
                          callback={directionsCallback}
                          onLoad={() => {
                            setIsDirectionLoading(true);
                          }}
                        />
                      )}
                      {directions && (
                        <DirectionsRenderer
                          options={{
                            preserveViewport: true,
                            suppressMarkers: true,
                          }}
                          directions={directions}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}

          {centerLocation && <CenterMap />}
          {centerViewerLocation && <CenterViewerLocationMap />}
          <ZoomOut />

          <PolylineRender />

          {viewerLocation?.lat && viewerLocation?.lng && (
            <>
              {viewerLocation?.heading || viewerLocation?.userDirection ? (
                <Marker
                  opacity={0.6}
                  icon={{
                    // size: new window.google.maps.Size(40, 40),
                    // scaledSize: new window.google.maps.Size(40, 40),
                    // origin: new window.google.maps.Point(0, 0),
                    // anchor: new window.google.maps.Point(0, 0),

                    // origin: new window.google.maps.Point(10, 10),
                    anchor: new google.maps.Point(0, 2.6),

                    // url: redArrowNavigation,
                    // rotation: 45,
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    scale: 8,
                    fillColor: 'red',
                    fillOpacity: 1,
                    strokeWeight: 2,
                    strokeColor: 'red',
                    // rotation: 360 - viewerLocation?.heading,

                    rotation: viewerLocation?.heading
                      ? viewerLocation?.heading
                      : viewerLocation?.userDirection !== null
                      ? viewerLocation?.userDirection
                      : 0,
                  }}
                  position={viewerLocation}
                  onClick={() => setViewerInfoWindow(true)}
                />
              ) : (
                <>
                  <Marker
                    opacity={0.6}
                    icon={{
                      size: new window.google.maps.Size(40, 40),
                      scaledSize: new window.google.maps.Size(40, 40),
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(20, 20),

                      url: purpleCircleMarker,
                    }}
                    position={viewerLocation}
                    onClick={() => setViewerInfoWindow(true)}
                  />
                </>
              )}
              <Circle
                center={viewerLocation}
                options={{
                  ...purpleCircleOptions,
                  radius: viewerLocation?.hAccuracy,
                }}
              />
              {directions && (
                <Marker
                  opacity={0.9}
                  icon={{
                    size: new window.google.maps.Size(40, 40),
                    scaledSize: new window.google.maps.Size(40, 40),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(20, 40),

                    url: redMapMarker,
                  }}
                  position={
                    directions?.routes[0]?.legs[0]?.end_location ?? null
                  }
                  onClick={() => setEndAddressInfoWindow(true)}
                />
              )}
            </>
          )}

          {lastKnownLocationInfoWindow && (
            <InfoWindow
              position={lastKnownLocationPath?.at(0)}
              onCloseClick={() => setLastKnownLocationInfoWindow(false)}>
              <div>
                <h3>Last Known Location</h3>
                <p>
                  <b>Date</b>:&nbsp;
                  {getTimeFromTimestamp(
                    lastKnownLocationPath?.at(0)?.timestamp
                  )}
                </p>
                <p>
                  <b>GPS</b>: {showField(lastKnownLocationPath?.at(0)?.lat, 10)}
                  ,{showField(lastKnownLocationPath?.at(0)?.lng, 10)}
                </p>
              </div>
            </InfoWindow>
          )}
          {initialInfoWindow && (
            <InfoWindow
              position={path?.at(0)}
              onCloseClick={() => setInitialInfoWindow(false)}>
              <div>
                <h3>Initial Location</h3>
                <p>
                  <b>Date</b>:&nbsp;
                  {getTimeFromTimestamp(path?.at(0)?.timestamp)}
                </p>
                <p>
                  <b>GPS</b>: {showField(path?.at(0)?.lat, 10)},
                  {showField(path?.at(0)?.lng, 10)}
                </p>
              </div>
            </InfoWindow>
          )}

          {viewerInfoWindow && (
            <InfoWindow
              position={viewerLocation}
              onCloseClick={() => setViewerInfoWindow(false)}>
              <div>
                <h3>Your Location:</h3>
                <p>
                  <b>GPS</b>: {showField(viewerLocation?.lat, 10)},
                  {showField(viewerLocation?.lng, 10)}
                </p>
              </div>
            </InfoWindow>
          )}

          {endAddressInfoWindow && directions && (
            <InfoWindow
              position={directions?.routes[0]?.legs[0]?.end_location}
              onCloseClick={() => setEndAddressInfoWindow(false)}>
              <div>
                <h3>End Route Address:</h3>
                <p>
                  <b>Address</b>: {directions?.routes[0]?.legs[0]?.end_address}
                </p>
              </div>
            </InfoWindow>
          )}

          {currentInfoWindow && (
            <InfoWindow
              position={path?.at(-1)}
              onCloseClick={() => setCurrentInfoWindow(false)}>
              <div>
                <h3>Emergency Contact Location:</h3>
                <p>
                  <b>Date</b>:&nbsp;
                  {getTimeFromTimestamp(path?.at(-1)?.timestamp)}
                </p>
                <p>
                  <b>GPS</b>: {showField(path?.at(-1)?.lat, 10)},
                  {showField(path?.at(-1)?.lng, 10)}
                </p>
              </div>
            </InfoWindow>
          )}
          {Array.isArray(path) && path?.length > 0 ? (
            <>
              {isPosition(path?.at(0)) && !URLparams.get('mode') && (
                <Marker
                  opacity={0.7}
                  icon={{
                    size: new window.google.maps.Size(28, 28),
                    scaledSize: new window.google.maps.Size(28, 28),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(14, 14),
                    url: roundMarker,
                  }}
                  position={path?.at(0)}
                  onClick={() => setInitialInfoWindow(true)}
                />
              )}
              {isPosition(path?.at(-1)) && (
                <Marker
                  opacity={0.6}
                  icon={{
                    size: new window.google.maps.Size(40, 40),
                    scaledSize: new window.google.maps.Size(40, 40),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(20, 20),

                    url: redCircleMarker,
                  }}
                  position={path?.at(-1)}
                  onClick={() => setCurrentInfoWindow(true)}
                />
              )}
            </>
          ) : (
            <>
              {isPosition(lastKnownLocationPath?.at(0)) && (
                <Marker
                  opacity={0.7}
                  icon={{
                    size: new window.google.maps.Size(24, 24),
                    scaledSize: new window.google.maps.Size(24, 24),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(12, 12),
                    url: redCircleMarker,
                  }}
                  position={lastKnownLocationPath?.at(0)}
                  onClick={() => setLastKnownLocationInfoWindow(true)}
                />
              )}
            </>
          )}
          {Array.isArray(path) && path?.length > 0 ? (
            <>
              {/* Blue Circle Accuracy for Initial Location */}
              {/* {(path?.at(0)?.hAccuracy ?? false) && (
                <Circle
                  center={path?.at(0)}
                  options={{
                    ...circleOptions,
                    fillColor: '#3875EA',
                    strokeColor: '#4484D2',
                    radius: path?.at(0)?.hAccuracy,
                  }}
                />
              )} */}

              {(path?.at(-1)?.hAccuracy ?? false) && (
                <Circle
                  center={path?.at(-1)}
                  options={{
                    ...circleOptions,
                    radius: path?.at(-1)?.hAccuracy,
                  }}
                />
              )}
            </>
          ) : (
            <>
              {(lastKnownLocationPath?.at(0)?.hAccuracy ?? false) && (
                <Circle
                  center={lastKnownLocationPath?.at(0)}
                  options={{
                    ...circleOptions,
                    radius: lastKnownLocationPath?.at(0)?.hAccuracy,
                  }}
                />
              )}
            </>
          )}
        </>
      </GoogleMap>
    </>
  ) : (
    <>
      <Center>
        <Loader color="red" />
      </Center>
    </>
  );
}

export default React.memo(MapContainerMobile);

const useStyles = createStyles({
  container: {
    position: 'relative',
  },
  mapControl: {
    position: 'absolute',
    top: 100,
    left: 10,
    zIndex: 200,

    // Tablet
    '@media (min-width: 768px) and (max-width: 1023px) ': {
      top: 100,
    },
  },

  currentLocationIco: {
    position: 'absolute',
    top: 70,
    right: 5,

    zIndex: 200,
  },
});
